<style>
    .my-clipper {
        width: 100vw;
        max-width: 700px;
    }
</style>

<template>
    <div ref="container" style="width:100vw;max-width:700px;">
        <input ref="uploader" class="d-none" type="file" accept="image/*" @change="onFileChanged">
        <img v-if="imageResult.length > 0" class="result" :src="imageResult" alt="" width="100%">
        <clipper-fixed v-else class="my-clipper" ref="clipper" :src="imgURL" :ratio="ratio" :area="80" bg-color="#eeeeee" :rotate="rotation" :round="round">
            <div class="placeholder" slot="placeholder" style="width:100%;text-align:center;background-color:#eeeeee;" :style="{ height: height + 'px', lineHeight: height + 'px' }" @click="onUploadClick">Tap to load photo</div>
        </clipper-fixed>

        <div style="text-align:center;margin-top:10px;">
            <v-btn fab large color="transparent" elevation="0" @click="rotateLeft"><v-icon style="font-size:48px;">mdi-rotate-left</v-icon></v-btn> 
            <v-btn rounded large color="transparent" elevation="0" @click="newImage"><v-icon style="font-size:36px;">mdi-circle-slice-8</v-icon></v-btn>
            <v-btn fab large color="transparent" elevation="0" @click="rotateRight"><v-icon style="font-size:48px;">mdi-rotate-right</v-icon></v-btn>
        </div>

        <v-container>
            <v-row justify="center" style="margin-top:10px;text-align:center;">
                <v-col cols="6">
                    <v-btn depressed :disabled="!this.selectedFile" :loading="loading" color="primary" @click="done" style="width:150px;font-weight:bold;">Done</v-btn>
                </v-col>
                <v-col cols="6">
                    <v-btn depressed :disabled="loading" color="secondary" @click="cancel" style="width:150px;">Cancel</v-btn>
                </v-col>                
            </v-row>
        </v-container>
    </div>
</template>

<script>
import { clipperFixed } from "vuejs-clipper";

export default {
    props: ["referrer", "referrerParams", "ratio", "link", "image", "save", "round", "onCancel", "onDone"], 
    data: function () {
        return {
            imgURL: "",
            imageResult: "",
            imageBlob: null,
            selectedFile: null,
            isSelecting: false,
            height: 375 / (3/4),
            rotation: 0,
            user: null,
            account: null,
            clipped: false,
            loading: false
        }
    },
    mounted: function () {
        this.resize();
    },
    created() {
    },

    methods: {
        async done() {
            this.loading = true;
            if (!this.clipped) {
                await this.getResult();
            }
            
            var formData = new FormData();
            formData.append("file", this.imageBlob, this.selectedFile.name);
            if (this.image) {
                formData.append("ref", this.image.container); 
                formData.append("entity", this.image.entity); 
                formData.append("version", this.image.version); 
            }
            else if (this.link) {
                formData.append("ref", this.link.ref); 
                formData.append("refId", this.link.refId);
                formData.append("field", this.link.field);
            }

            formData.append("save", this.save);

            var uploadResult = await this.$api.post("image/upload", formData, { token: this.$root.authToken }, true, true);

            if (this.onDone) {
                this.onDone({ image: uploadResult.data });
            }
            
            this.loading = false;
       },
        reset() {
            this.clipped = "";
            this.imgURL = "";
            this.imageResult =  "";
            this.selectedFile = null;
            this.isSelecting = false;
            this.rotation = 0;
            this.$refs.uploader.value = null;     
        },
        newImage() {
            this.reset();
            this.onUploadClick();
        },
        cancel() {
            if (this.onCancel) {
                this.onCancel();
            }
        },
        resize() {
            this.height = this.$refs.container.clientWidth / this.ratio;
        },
        rotateLeft() {
            var rotation = this.rotation;
            rotation -= 90;
            if (rotation == -360) {
                rotation = 0;
            }
            this.rotation = rotation;
        },
        rotateRight() {
            var rotation = this.rotation;
            rotation += 90;
            if (rotation == 360) {
                rotation = 0;
            }
            this.rotation = rotation;
        },
        onUploadClick() {
            this.isSelecting = true
            window.addEventListener('focus', () => {
                this.isSelecting = false
            }, { once: true })

            this.$refs.uploader.click()
        },
        async getResult() {
            const canvas = this.$refs.clipper.clip({ wPixel: 1080 }); //call component's clip method
            this.imageBlob = await new Promise(resolve => canvas.toBlob(resolve, "image/jpeg"));
            this.imageResult = canvas.toDataURL("image/jpeg", 1); //canvas->image
            this.clipped = true;
        },
        showData() {
        },
        onFileInfo() {
        },
        onFileChanged(e) {
            if (e.target.files.length !== 0) {
                this.selectedFile = e.target.files[0];
                
                if (this.imgURL) {
                    URL.revokeObjectURL(this.imgURL);
                }
                this.imgURL = window.URL.createObjectURL(this.selectedFile);
            }
        }        
    },
    components: {
        clipperFixed
    }
}
</script>